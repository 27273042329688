<template>
  <BOverlay :show="buzy">
    <BRow>
      <BCol
cols="12"
md="6"
>
        <RequestInfoCard
          :item="data"
          @updateField="updateField"
          @updateFields="updateFields"
        />
        <RequestCheckListsCard
          :item="data"
          @updateField="updateField"
        />
      </BCol>
      <BCol
cols="12"
md="6"
>
        <RequestPropsCard
          :item="data"
          @updateField="updateField"
          @updateFields="updateFields"
        />

        <RequestEquipmentCard
          :item="data"
          @updateField="updateField"
          @updateFields="updateFields"
        />
      </BCol>
    </BRow>
    <BRow class="mb-2">
      <BCol>
        <BButton
          class="mr-1"
          variant="primary"
          :disabled="buzy"
          @click="createEntity"
        >
          {{ t('dictionary.create') }}
        </BButton>
        <BButton
          variant="outline-primary"
          :disabled="buzy"
          @click="cancel"
        >
          {{ t('dictionary.cancel') }}
        </BButton>
      </BCol>
    </BRow>
  </BOverlay>
</template>

<script>
import { ref, provide, set } from '@vue/composition-api';
import {
  BOverlay, BRow, BCol, BButton,
} from 'bootstrap-vue';
import { useRouter } from '@core/utils/utils';
import { useI18n } from '@/hooks/useI18n';
import EntityModel from '@/utils/EntityModel';
import { useStore } from '@/hooks/useStore';

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    RequestInfoCard: () => import('@/components/requests/InfoCard.vue'),
    RequestPropsCard: () => import('@/components/requests/PropsCard.vue'),
    RequestEquipmentCard: () => import('@/components/requests/EquipmentCard.vue'),
    RequestCheckListsCard: () => import('@/components/requests/CheckListsCard.vue'),
  },
  props: {
    id: [String, Number],
  },
  setup() {
    const { t } = useI18n();
    const { router, route } = useRouter();

    const data = ref({
      // type_id: 1,
    });

    const buzy = ref(false);
    const { updateFieldHandler, updateFieldsHandler, createEntityHandler } = EntityModel();
    const store = useStore();

    const setDefaults = async () => {
      buzy.value = true;
      const types = (await store.dispatch('requests/types'))?.data;
      set(data.value, 'type', types[0]);
      set(data.value, 'type_id', types[0]?.id);

      const workTypes = (await store.dispatch('requests/workTypes'))?.data;
      set(data.value, 'work_type', workTypes[0]);
      set(data.value, 'work_type_id', workTypes[0]?.id);

      const currentUser = store.state?.user?.data || {};
      const {
        avatar, email, firstname, id, patronymic, role, surname,
      } = currentUser;
      const manager = {
        avatar, email, firstname, id, patronymic, role, surname,
      };
      set(data.value, 'manager', manager);
      set(data.value, 'manager_id', id);

      // deadline_at:"2022-09-19T12:00:00"
      const deadline = new Date();
      deadline.setDate(deadline.getDate() + 1);
      deadline.setHours(12);
      deadline.setMinutes(0);
      set(data.value, 'deadline_at', deadline.toISOString());
      buzy.value = false;
    };
    setDefaults();

    const updateField = async ({ field, value }) => {
      data.value = await updateFieldHandler({
        action: null,
        entity: data.value,
        field,
        value,
      });
    };

    const updateFacility = async (forceId) => {
      if (data.value.facility_id || forceId) {
        const response = await store.dispatch('facilities/getFacility', data.value.facility_id || forceId);
        if (response.result) {
          updateField({
            field: 'facility',
            value: response.data,
          });
        }
      }
    };
    const query = ref(route.value.query || {});
    (async () => {
      if (query.value.facility_id) {
        await updateFacility(query.value.facility_id);
        data.value.facility_id = query.value.facility_id;
      }
    })();
    const updateFields = async ({ model, silent = false }) => {
      data.value = await updateFieldsHandler({
        model,
        silent,
        action: null,
        entity: data.value,
      });

      updateFacility();
    };

    const createEntity = async () => {
      buzy.value = true;
      try {
        const createdEntity = await createEntityHandler({
          action: 'requests/create',
          entity: data.value,
        });
        router.push({
          name: 'requests-view',
          params: {
            id: createdEntity.data.id,
          },
        });
      } catch (e) {
        console.error(e);
      }
      buzy.value = false;
    };
    const cancel = () => {
      router.push({
        name: 'requests',
      });
    };

    const needToUpdate = () => {
      updateFacility();
    };
    provide('needToUpdate', needToUpdate);

    return {
      t,
      data,
      buzy,
      updateField,
      updateFields,
      createEntity,
      cancel,
    };
  },
};
</script>
